import { Component } from "react";
import Form from "react-bootstrap/Form";

export type TDayCheckboxProps = {
  id: string;
  index: number;
  date: Date;
  checked: boolean;
  setSelectedIndex: Function;
};

export default class DayCheckbox extends Component<TDayCheckboxProps> {
  props!: TDayCheckboxProps;

  constructor(props: TDayCheckboxProps) {
    super(props);
  }

  handleInputChange(chb: any) {
    this.props.setSelectedIndex(this.props.index, chb.checked);
  }

  render() {
    return (
      <Form.Check
        label={new Intl.DateTimeFormat("pl-PL", {
          weekday: "short",
        }).format(this.props.date)}
        type="checkbox"
        id={this.props.id}
        checked={this.props.checked}
        onChange={(e) => this.handleInputChange(e.currentTarget as any)}
      />
    );
  }
}
