import { Component } from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Days from "./Days";
import "./Calendar.scss";

type TState = {
  date: Date;
  count: number;
  selectedDays: Date[];
  until: Date | null;
};

export default class Calendar extends Component {
  state: TState;

  constructor(props: any) {
    super(props);
    this.state = {
      date: new Date(),
      count: 36,
      selectedDays: [],
      until: null,
    };
    this.onDateChange = this.onDateChange.bind(this);
    this.onCountChange = this.onCountChange.bind(this);
    this.onDaysSelectionChange = this.onDaysSelectionChange.bind(this);
  }

  onDateChange(input: any) {
    this.setState({
      date: input.valueAsDate,
    });
    this.setUntil();
  }

  onCountChange(count: number) {
    this.setState({
      count: count,
    });
    this.setUntil();
  }

  onDaysSelectionChange(selectedDays: Date[]) {
    this.setState({
      selectedDays: selectedDays,
    });
    this.setUntil();
  }

  private setUntil(): void {
    this.setState((state: TState, props) => {
      let until = null;

      if (state.selectedDays.length && state.count && state.date) {
        const msDay = 3600 * 24 * 1000;
        let count = state.count;

        until = new Date(state.date);
        until.setHours(0, 0, 1);

        while (count > 0) {
          for (let j = 0; j < state.selectedDays.length; j++) {
            if (state.selectedDays[j].getDay() === until.getDay()) {
              count--;
              break;
            }
          }
          count && until.setTime(until.getTime() + msDay);
        }
      }

      return {
        until: until,
      };
    });
  }

  render() {
    return (
      <Container className="container-sm">
        <Form.Group as={Row}>
          <Form.Label column sm="1">
            Od:
          </Form.Label>
          <Col sm="11">
            <Form.Control
              id="dateFrom"
              type="date"
              className="input-date"
              onChange={(e) => this.onDateChange(e.currentTarget as any)}
              value={this.state.date.toISOString().substring(0, 10)}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="1">
            Ile?:
          </Form.Label>
          <Col sm="11">
            <Form.Control
              type="numeric"
              className="input-count"
              min={0}
              max={99999}
              value={this.state.count}
              step={1}
              onChange={(e) => this.onCountChange(e.currentTarget.value as any)}
            />
          </Col>
        </Form.Group>

        <Row>
          <Col className="col-1">&nbsp;</Col>
          <Col>
            <Days selectionChange={this.onDaysSelectionChange} />
          </Col>
        </Row>

        <Row>
          {/* <Col> */}
          <h2 className="until">
            {!this.state.until
              ? "???"
              : new Intl.DateTimeFormat("pl-PL", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }).format(this.state.until)}
          </h2>
          {/* </Col> */}
        </Row>
      </Container>
    );
  }
}
