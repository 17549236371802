import { Component } from "react";
import DayCheckbox, { TDayCheckboxProps } from "./DayCheckbox";

type TState = {
  days: TDayCheckboxProps[];
  selectedDays: Date[];
};

type TDaysProps = {
  selectionChange: Function;
};

export default class Days extends Component<TDaysProps> {
  selectionChange!: Function;
  state: TState;

  constructor(props: TDaysProps) {
    super(props);
    this.state = {
      days: [],
      selectedDays: [],
    };
    this.setSelectedIndex = this.setSelectedIndex.bind(this);
  }

  componentDidMount() {
    const msDay = 3600 * 24 * 1000;
    let date = new Date(),
      days = [];

    date.setHours(0, 0, 1, 0);

    //goto Monday
    if (date.getDay() > 1) {
      while (date.getDay() > 1) {
        date.setTime(date.getTime() - msDay);
      }
    } else if (date.getDay() < 1) {
      while (date.getDay() < 1) {
        date.setTime(date.getTime() + msDay);
      }
    } else {
      //it's Monday
    }

    let checked = false,
      checkedIndex = -1;

    //until Sunday
    for (let i = 0; i < 7; i++) {
      checked =
        date.getDay() === 1 || date.getDay() === 3 || date.getDay() === 5
          ? true
          : false;
      if (checkedIndex === -1) {
        checkedIndex = i;
      }
      days.push({
        date: new Date(date),
        checked: checked,
        id: "day-id-" + date.getDay(),
      });
      date.setTime(date.getTime() + msDay);
    }

    this.setState({
      days: days,
    });

    this.setSelectedIndex(checkedIndex, true); //trigger parent logic
  }

  setSelectedIndex(index: number, checked: boolean) {
    this.setState((prevState: TState, props: TDaysProps) => {
      if (index >= 0) {
        prevState.days[index].checked = checked;
      }

      let selected = [];
      for (let i = 0; i < prevState.days.length; i++) {
        if (prevState.days[i].checked) {
          selected.push(prevState.days[i].date);
        }
      }

      props.selectionChange(selected);

      return {
        days: prevState.days,
      };
    });
  }

  render() {
    return (
      <div className="days">
        {this.state.days.map((day: TDayCheckboxProps, index: number) => (
          <DayCheckbox
            key={index}
            index={index}
            id={day.id}
            date={day.date}
            checked={day.checked}
            setSelectedIndex={this.setSelectedIndex}
          />
        ))}
      </div>
    );
  }
}
